<template>
    <div :class="disabled ? 'disabled' : ''">
    <div class="dark-text header-w-bg" v-if='showDiagnosisLabel'><slot>Diagnosis</slot></div>
    <div v-if="diag_codes && diag_codes.length" class="bordered">
        <div class="multiselect bottom-15" v-if='useDiagnosisCustom && diag_custom'>
            <div  class="flex center single-service margin-0 has-primary-label">
                <label class="right-5 bottom-0" :for="'radio_' + diag_custom.data_id">{{
                        diag_custom.primary ? 'Primary' : ''
                    }}</label>
                <input class="color"
                    type="radio"
                    name="dx_code_custom"
                    :value="diag_custom.data_id"
                    v-model="dx_model"
                    :id="'radio_' + 'diag_custom'"
                />
                <input
                    type="text"
                    class="form-control max800"
                    v-model="diag_custom.value"
                    :id="'input' + '_diag_custom'"
                    placeholder='Type in diagnosis(es)'
                    :disabled="disabled"
                />

            </div>
            <span class="andor block top-15">And/or</span>
        </div>

        <div
            v-for="(dx_code, key) in diag_codes"
            :key="dx_code.data_id"
            class="flex center single-service margin-0 has-primary-label"
        >
            <div class="multiselect bottom-15">
                <!-- <label>Select DX (ICD-10) code</label> -->
                <div class="flex center">
                    <label class="right-5 bottom-0" :for="'radio_' + dx_code.data_id">{{
                            dx_code.primary ? 'Primary' : ''
                        }}</label>
                    <input class="color"
                        type="radio"
                        name="dx_code"
                        :value="dx_code.data_id"
                        v-model="dx_model"
                        :id="'radio_' + dx_code.data_id"
                    />
                    <Multiselect
                        class="max800 color"
                        v-model="dx_code.selected"
                        :options="options"
                        :key="'select_' + dx_code.data_id"
                        track-by="id"
                        :custom-label="serviceCodeDesc"
                        @select="
                                            (e) => {

                                                modifyMenu(e, dx_code.selected, 'id')
                                                // options = calibrateMenu(e, dx_code.selected, 'id', options);
                                            }
                                        "
                        :disabled='disabled'

                    >
                    </Multiselect>
                    <!-- @input="() => {$forceUpdate()}" -->
                    <button class="delete-icon" type='button' @click.prevent="remove_dx(key)" v-if='isRemoveable'>
                                        <span class="button material-icons-outlined"
                                        >delete</span
                                        >
                    </button>
                </div>
            </div>
        </div>
    </div>
        <button class="add-service_btn no-bg dark-text add top-10" @click.prevent="add_dx()" v-if='isAddable && !useDiagnosisCustom && !diag_custom' type='button'>
            <span class="material-icons purple">add_box</span> Add additional DX / ICD code
        </button>
        <button class="add-service_btn no-bg dark-text add top-10" @click.prevent="add_dx()" v-if='isAddable && useDiagnosisCustom && diag_custom' type='button'>
          <span class="material-icons purple">add_box</span> Add additional ICD code
        </button>
    </div>
</template>

<script>
    import { calibrateMenu, calibrateMenuToDisabledStatus, generateId } from '@/util/genericUtilityFunctions';
    import { form_data } from '@/util/apiRequests';


    export default {
        name: 'DiagnosisList',
        props: {
            keyToModifyBy:{
                type: String,
                default: 'diagnosis_codes'
            },
            metaKeyToModifyBy: {
                type: String,
                default: 'diagnosis_codes_options'
            },
            showDiagnosis: {
                type: Boolean,
                default: true
            },
            showDiagnosisLabel: {
                type: Boolean,
                default: true
            },
            maxCountOfDiagnosis: {
                type: Number,
            },
            maxDefaultToStartWith: {
                type: Number,
                default: 1
            },
            diag_codes: {
                type: Array,
                // default: function() {
                //     return [];
                // }
            },
            modifyRecord: {
                type: Function,
                required: true
            },
            //this will use the api to get the list of diagnosis options, instead of passing it from the parent component
            useApiToRetrieveOptions: {
                type: Boolean,
                default: false
            },
            diag_options: {
                type: Array,
                default: () => []
            },
            isRemoveable: {
                type: Boolean,
                default: true
            },
            isAddable: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            useTreatmentPlan: {
                type: Boolean,
                required: false,
                default: false
            },
            client_id: {
                type: [Number, String],
                required: false,
            },
            record_id: {
                type: [Number, String],
                required: false,
            },
            diag_custom: {
                type: Object,
                required: false,
            },
            useDiagnosisCustom: {
                type: Boolean,
                required: false,
                default: false
            },

        },
        data() {
            return {
                options: [],
                calibrateMenu,
                calibrateMenuToDisabledStatus,
                generateId,
            }
        },
        methods: {
            modifyMenu(e, dx_code, key) {

                // this.options = calibrateMenu(e, dx_code.selected, key, this.options);
                if(dx_code){
                    this.options = calibrateMenuToDisabledStatus(e, dx_code, key, this.options);
                }
            },
            removeOptionsFromMenu() {

                this.options = this.options.map((element) => {
                    if (this.diag_codes.some((dx) => element.id == dx.selected?.id)) {
                        element.$isDisabled = true;
                    }
                    return element;
                });
            },
            serviceCodeDesc({ service_code, abbrev }) {
                if (service_code && abbrev) {
                    return `${service_code} ${abbrev}`;
                }
                return 'Select ICD code';
            },
            add_dx() {
                if(this.modifyRecord) {
                    let itemToAdd = {
                        data_id: generateId(),
                        selected: {},
                        primary: false,
                    };
                    if(this.diag_codes.length <= 0){
                        itemToAdd.primary = true;
                    }
                    let arrPlusItem = [...this.diag_codes, itemToAdd];

                    this.modifyRecord(this.keyToModifyBy, arrPlusItem);
                }
            },
            generateSeveralDxCodes(count) {
                let arr = [];
                for (let i = 0; i < count; i++) {
                    if(i == 0 && this.useDiagnosisCustom === false) {
                        arr.push({
                            data_id: generateId(),
                            selected: {},
                            primary: true,
                        });
                    } else {
                        arr.push({
                            data_id: generateId(),
                            selected: {},
                            primary: false,
                        });
                    }
                }
                return arr;
            },
            remove_dx(index) {
                if(this.modifyRecord) {
                    let arrMinusItem = [...this.diag_codes];
                    let copy = this.diag_codes[index];
                    if(copy.primary == true && arrMinusItem.length > 1){
                        arrMinusItem.splice(index, 1);
                        arrMinusItem[0].primary = true;
                    }else {
                        arrMinusItem.splice(index, 1);
                    }
                    // this.options = this.calibrateMenu(null, copy.selected, 'id', this.options);
                    this.options = this.calibrateMenuToDisabledStatus(null, copy.selected, 'id', this.options);

                    this.modifyRecord(this.keyToModifyBy, arrMinusItem);
                }
            },
            async get_dx_codes() {
                const res = await this.$api.get(`/services/dx-codes`);
                let options = res.data?.map((element) => {
                    return { service_code: element.service_code, abbrev: element.abbrev, id: element.id };
                });
                this.options = options;
            },

            async get_treatment_plan(){
                // if progress note is new, then get treatment plan from the server
                if(this.record_id == 0){
                    try {
                        let res = await this.$api.get(form_data.getTreatmentPlan(this.client_id));
                        /** If the client has a treatment plan on file **/
                        if (res.data[0] && res.status == 200) {
                            let txPlan = res.data[0];
                            txPlan = JSON.parse(txPlan.raw_data);
                            let dx_codes = txPlan.diagnosis_codes;
                            this.$emit('foundTreatmentPlan', dx_codes);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                    return
                }

                // if progress note already exists, then use db data,
                return

            }


        },
        computed: {
            dx_model: {
                get() {

                    try {
                        if(this.diag_custom?.primary){
                            return this.diag_custom.data_id;
                        }
                        return this.diag_codes.find((d) => d.primary).data_id;
                    }catch(e) {
                        return null;
                    }
                },
                set(value) {

                    // from rec.diag_codes, the primary code
                    let arr = [...this.diag_codes];
                    arr = arr.map((d) => {
                        if (d.data_id === value) {
                            if(this.useDiagnosisCustom) {
                                this.modifyRecord('diag_custom', {
                                    ...this.diag_custom, primary: false
                                })
                            }
                            return { ...d, primary: true };
                        } else {
                            return { ...d, primary: false };
                        }
                    });
                    this.modifyRecord(this.keyToModifyBy, arr);

                    if(this.useDiagnosisCustom && this.diag_custom.data_id === value) {
                        this.modifyRecord('diag_custom', {
                            ...this.diag_custom, primary: true
                        })
                    }
                }
            },
        },
        async created() {

            if(this.maxDefaultToStartWith && (!this.diag_codes || this.diag_codes.length === 0)) {
                if(this.useDiagnosisCustom){
                    this.modifyRecord('diag_custom', {
                        data_id: generateId(),
                        value: "",
                        primary: true,
                    });
                }
                this.modifyRecord(this.keyToModifyBy,  this.generateSeveralDxCodes(this.maxDefaultToStartWith));
            }
            if(this.useApiToRetrieveOptions) {
                await this.get_dx_codes();
            }
            if(this.useTreatmentPlan == true){
                await this.get_treatment_plan();
            }
            else {
                if(this.diag_options && this.diag_options.length > 0) {
                    this.options = this.diag_options;
                }
            }
            this.removeOptionsFromMenu();
        }


    };
</script>