<template>
    <div>
        <!-- TAB INPUTS -->
        <input id="tab-1" checked class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="tab-2" class="page-tab-input" name="page-tab-input" type="radio" />

        <!-- TABS CONTAINER -->
        <div class="user-settings-header page-tabs-container do-not-print-me">
            <label class="page-tab" for="tab-1">Current Treatment Plan</label>
            <label class="page-tab" for="tab-2">Client's Note History</label>
        </div>
        <section v-if="!loading" v-disable-all="!pageMeta.permissions.edit" class="tabbed-page current-treat-plan">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :form_data_id="record_id"
                            :siggys="original_signature_ids.length"
                            @getClient="getClient"
                        />
                        <br />
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>
                <!-- TABBED PAGE SECTIONS -->
                <div class="treatment-plan">
                    <div>
                        <h2 class="margin-0">Treatment Plan</h2>
                        <div v-if="this.dateEditing">
                            <div class="bottom-5">Editing the date</div>
                            <div class="flex center max500 bottom-20">
                                <input v-model="date" class="right-10 block" type="date" />
                                <input v-model="time" class="right-10 block" type="time" />
                                <button class="right-10 block" @click.prevent="toggleDateEditing">Done</button>
                                <span class="material-icons-outlined pointer" @click.prevent="toggleDateEditing"
                                    >close</span
                                >
                            </div>
                        </div>
                        <div v-else class="dark-text">
                            {{ dateFormatted }}
                            <!-- <button @click.prevent="toggleDateEditing">Edit Time</button> -->
                            <span class="material-icons-outlined pointer" @click.prevent="toggleDateEditing">edit</span>

                            <!-- <span class="material-icons-outlined">edit</span> -->
                        </div>
                    </div>
                    <button
                        v-if="signature_ids.length && original_signature_ids.length"
                        type="button"
                        @click="signature_ids = []"
                    >
                        Edit & Create Addendum
                    </button>
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <div v-if="original_signature_ids.length && !signature_ids.length" class="dark-text header-w-bg  addendum">
                        You are now editing the original signed document to create an addendum
                    </div>
                    <hr class="m-15" />
                    <div class="align-right do-not-print-me">
                        <button class="secondary" type="button" @click="printView()">Print View</button>
                    </div>

                    <DiagnosisList
                        :client_id="client_id"
                        :diag_codes="rec.diagnosis_codes"
                        :modify-record="modifyRecord"
                        :record_id="record_id"
                        :use-treatment-plan="true"
                        :useApiToRetrieveOptions="true"
                        key-to-modify-by="diagnosis_codes"
                    ></DiagnosisList>

                    <div class="avoid-break">
                        <div class="dark-text header-w-bg">Counselors</div>
                        <div class="bordered counselors">
                            <div
                                v-for="(counselor, idx) in rec.counselor_team"
                                :key="counselor.data_id"
                                class="flex center top-0 bottom-15 has-primary-label"
                            >
                                <label :for="counselor.data_id" class="right-5 font-14 dark-text">{{
                                    counselor.primary ? 'Primary' : ''
                                }}</label>
                                <input
                                    :id="counselor.data_id"
                                    v-model="counselor_model"
                                    :value="counselor.data_id"
                                    name="counselor"
                                    type="radio"
                                />
                                <Dropdown
                                    v-model="rec.counselor_team[idx].id"
                                    :label="''"
                                    :options="availableCounselors"
                                    :placeholder="
                                        counselor.primary
                                            ? 'Select a Primary Counselor'
                                            : 'Select a Secondary Counselor'
                                    "
                                    class="block"
                                />
                                <button class="delete-icon">
                                    <span
                                        class="button material-icons-outlined"
                                        type="button"
                                        @click.prevent="remove_counsellor(idx)"
                                    >
                                        delete
                                    </span>
                                </button>
                            </div>
                            <button class="no-bg dark-text add top-10" type="button" @click.prevent="add_counselor()">
                                <span class="material-icons purple">add_box</span> Add Counselor
                            </button>
                            <!--                        <Input class="" type='text' v-model='rec.counselors' label='Primary' />-->
                        </div>
                    </div>
                    <div class="avoid-break">
                        <div class="dark-text header-w-bg">Other Agencies involved</div>
                        <div v-for="(agency, idx) in rec.agencies" :key="'agency_' + idx" class="flex colx2">
                            <Input
                                v-model="rec.agencies[idx].agency_name"
                                class="flex-1 right-15 bottom-0"
                                label="Agency Name"
                                type="text"
                            />
                            <div class="block">
                                <ExpandableTextArea
                                    v-model="rec.agencies[idx].agency_plan"
                                    :limit="500"
                                    inputClass="block right-30 do-not-print-me"
                                    inputStyle="min-height:20px;"
                                    label="Plan"
                                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                    type="text"
                                />
                            </div>
                            <button class="bottom-30 delete-icon" @click.prevent="remove_agency(idx)">
                                <span class="button material-icons-outlined">delete</span>
                            </button>
                        </div>
                        <button class="no-bg dark-text add" type="button" @click.prevent="add_agency()">
                            <span class="material-icons purple">add_box</span> Add Agency
                        </button>
                    </div>

                    <div class="avoid-break">
                        <div class="dark-text header-w-bg">Medications</div>
                        <div v-for="(medication, idx) in rec.medications" :key="'medication_' + idx">
                            <div class="flex center">
                                <ExpandableTextArea
                                    v-model="rec.medications[idx].text"
                                    :limit="500"
                                    class="block flex-1"
                                    inputClass="flex do-not-print-me"
                                    inputStyle="min-height:20px;"
                                    label="Medications"
                                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                    type="text"
                                />
                                <button class="delete-icon" @click.prevent="remove_medication(idx)">
                                    <span class="button material-icons-outlined">delete</span>
                                </button>
                            </div>
                        </div>
                        <button
                            v-show="rec.medications.length === 0"
                            class="no-bg dark-text add"
                            type="button"
                            @click.prevent="add_medications()"
                        >
                            <span class="material-icons purple">add_box</span> Add Medications
                        </button>
                    </div>
                    <div class="avoid-break">
                        <div class="dark-text header-w-bg">Presenting Problem</div>
                        <ExpandableTextArea
                            v-model="rec.problems"
                            :limit="500"
                            inputClass="block do-not-print-me"
                            inputStyle="min-height:20px;"
                            label=""
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                        />
                    </div>

                    <!-- <div class="dark-text header-w-bg">Present Level of Performance</div>-->
                    <!-- <Input type="text" v-model="rec.present_level" label="" />-->

                    <div class="avoid-break">
                        <div class="dark-text header-w-bg">Goals, Objectives, Interventions</div>
                        <div class="tx-goals">
                            <div
                                v-for="(goal, goalkey) in rec.goals"
                                :key="goal.data_id"
                                class="goal bordered top-40 bottom-10 avoid-break"
                            >
                                <div class="bottom-15">
                                    <label for="'asam_'+goalkey"
                                    >ASAM Dimension for this Goal (if applicable)
                                        <Multiselect
                                            :options="asam_options"
                                            :value="asam_value(goalkey)"
                                            label="text"
                                            track-by="id"
                                            @input="asam_input(goalkey, $event.id)"
                                        />
                                    </label>
                                </div>
                                <div class="goal-header flex top avoid-break">
                                    <!--<Input type="text" v-model="goal.goal" label="Goal" class="flex-1 right-15" />
                                    <Input
                                        class="right-15"
                                        type="date"
                                        v-model="goal.goal_date"
                                        label="Date to Achieve by"
                                    />-->
                                    <ExpandableTextArea
                                        v-model="goal.goal"
                                        :limit="500"
                                        class="flex-2 right-15"
                                        inputClass="block do-not-print-me"
                                        inputStyle="min-height:20px;"
                                        label="Goal"
                                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                        type="text"
                                    />
                                    <div class="flex flex-1 bottom">
                                        <Input
                                            v-model="goal.goal_date"
                                            class="right-10 flex-1"
                                            label="Date to Achieve by"
                                            type="date"
                                        />
                                        <button class="delete-icon no-bg dark-text pad-5">
                                            <span class="material-icons-outlined" @click.prevent="del_goal(goalkey)"
                                                >delete</span
                                            >
                                        </button>
                                    </div>
                                </div>

                                <div class="objectives left-20 avoid-break">
                                    <div
                                        v-for="(objective, objectivekey) in goal.objectives"
                                        :key="objective.data_id"
                                        class="objective flex top"
                                    >
                                        <ExpandableTextArea
                                            v-model="objective.objective"
                                            :limit="500"
                                            class="flex-2 right-15"
                                            inputClass="block do-not-print-me"
                                            inputStyle="min-height:20px;"
                                            label="Objective"
                                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                            type="text"
                                        />
                                        <div class="flex flex-1 bottom">
                                            <Input
                                                v-model="objective.objective_date"
                                                class="right-10 flex-1"
                                                label="Objective Date"
                                                type="date"
                                            />
                                            <button class="delete-icon no-bg dark-text pad-5">
                                                <span
                                                    class="material-icons-outlined"
                                                    @click="del_objective(goalkey, objectivekey)"
                                                    >delete</span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        class="add-objective_btn no-bg dark-text add bottom-10"
                                        @click.prevent="add_objective(goalkey)"
                                    >
                                        <span class="material-icons purple">add_box</span> Add Objective
                                    </button>
                                </div>
                                <div class="interventions left-20 avoid-break">
                                    <div
                                        v-for="(intervention, interventionkey) in goal.interventions"
                                        :key="intervention.data_id"
                                        class="intervention flex top"
                                    >
                                        <!--<Input
                                            type="text"
                                            v-model="intervention.intervention"
                                            label="Intervention"
                                            class="flex-1 right-15"
                                        />-->
                                        <ExpandableTextArea
                                            v-model="intervention.intervention"
                                            :limit="500"
                                            class="flex-2 right-15"
                                            inputClass="block do-not-print-me"
                                            inputStyle="min-height:20px;"
                                            label="Intervention"
                                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                            type="text"
                                        />
                                        <div class="flex flex-1 bottom">
                                            <Input
                                                v-model="intervention.parties"
                                                class="right-10 flex-1"
                                                label="Responsible Parties"
                                                type="text"
                                            />
                                            <button class="delete-icon no-bg dark-text pad-5">
                                                <span
                                                    class="material-icons-outlined"
                                                    @click.prevent="del_intervention(goalkey, interventionkey)"
                                                    >delete</span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        class="add-intervention_btn no-bg dark-text add"
                                        @click.prevent="add_intervention(goalkey)"
                                    >
                                        <span class="material-icons purple">add_box</span> Add Intervention
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="no-bg dark-text add" type="button" @click.prevent="add_goal()">
                        <span class="material-icons purple">add_box</span> Add Goal
                    </button>

                    <div class="dark-text header-w-bg">Referrals Suggested to Client</div>
                    <div
                        v-for="(provider, idx) in rec.referrals"
                        :key="provider.data_id"
                        class="flex bottom fullwidth bottom-15"
                    >
                        <Input
                            v-model="rec.referrals[idx].name"
                            class="flex-1 right-15"
                            label="Name of Provider"
                            type="text"
                        />
                        <Input
                            v-model="rec.referrals[idx].contact"
                            class="flex-2 right-5"
                            label="Contact Info"
                            type="text"
                        />
                        <button class="delete-icon" @click.prevent="remove_referral(idx)">
                            <span class="button material-icons-outlined">delete</span>
                        </button>
                    </div>
                    <button class="no-bg dark-text add" type="button" @click.prevent="add_referral">
                        <span class="material-icons purple">add_box</span> Add Provider
                    </button>
                    <div class="avoid-break">
                        <div class="dark-text header-w-bg">Frequency, Duration, End Date</div>
                        <div>
                            <div
                                v-for="service in rec.services"
                                :key="service.data_id"
                                class="flex bottom single-service top-0 bottom-5 avoid-break"
                            >
                                <div class="multiselect right-15 flex-3">
                                    <label>Service code</label>
                                    <select
                                        :key="'select_' + service.data_id"
                                        v-model="service.cpt_code"
                                        :disabled="service.disabled"
                                    >
                                        <option
                                            v-for="(option, idx) in filteredServiceOptions"
                                            :key="'service_' + service.data_id + '-' + idx"
                                            :disabled="option.disabled"
                                            :value="option.service_code_id"
                                        >
                                            {{ option.service_code }} - {{ option.long_descrip }}
                                        </option>
                                    </select>
                                </div>
                                <Input
                                    v-model="service.frequency"
                                    class="flex-1 right-15"
                                    label="Frequency"
                                    type="text"
                                />
                                <Input
                                    v-model="service.completion_date"
                                    label="Estimated Completion Date"
                                    type="date"
                                />
                                <div class="delete-icon">
                                    <span class="material-icons-outlined" @click.prevent="removeCpt(key)">delete</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="no-bg dark-text add bottom-10" @click.prevent="addCpt()">
                        <span class="material-icons purple">add_box</span> Add additional service code
                    </button>

                    <Input v-model="rec.sessions_assigned" class="right-15" label="Sessions Assigned" type="text" />

                    <div class="top-20">
                        <Checkbox v-model="rec.med_dir_signature" label="Requires Medical Director Signature"/>
                    </div>

                    <div class="dark-text top-30 bottom-30 font-13 bold">
                        In selecting Sign & Submit, the provider attests this treatment plan is medically necessary and
                        appropriate to the client’s diagnosis and treatment needs and this document will subsequently be
                        sent to the client for signature.
                    </div>
                </div>

                <div v-if="!signature_ids.length" class="align-right">
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right secondary right-15"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <ConfirmButton :canedit="isEditable" class="inline-block" @click="updateRecord()"
                        >Save as Draft
                    </ConfirmButton>
                </div>
                <FormSignature
                    v-if="signature_ids.length > 0"
                    ref="form_signatures"
                    :signature_ids="signature_ids"
                    :user_id="user_id"
                    @getSignatures="getSignatures"
                />
                <FormSignatureButton
                    :fileName="pageMeta.component_name"
                    :original_rec="original_rec"
                    :rec="rec"
                    :record_id="record_id"
                    :save="updateRecord"
                    :signature_ids="signature_ids"
                    :signatures_info="signatures_info"
                    @fail="fail2sign"
                    @resetView="reloadData"
                    @showModal="showPdfModal"
                    >Sign and Submit
                </FormSignatureButton>
            </form>
            <VIfWorkableModal v-if="showCreatePdfModal" :hasCloseButton="false" data-html2canvas-ignore="true">
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </section>
        <section v-disable-all="!pageMeta.permissions.edit" class="tabbed-page current-treat-plan do-not-print-me">
            <h1>{{ client_data.last_name }}, {{ client_data.first_name }}</h1>
            <p>ID# {{ this.client_data.id }}, DOB {{ this.dob }}</p>
            <h3>View Previous Notes</h3>
            <p>
                Only signed and completed notes available for view, no drafts. If addendum needed for previous note, go
                to the client's completed forms in the<a class="weight-600">
                    <router-link :to="{ name: 'ClientDocuments', params: { client_id: this.client_id } }">
                        Documents & Forms tab of their profile
                    </router-link> </a
                >and select "see full list".
            </p>
            <div v-if="noteHistory.length < 1"><b>No previous Progress Notes on file for this client.</b></div>
            <div
                v-for="(note, noteKey) in noteHistory"
                :key="'note_' + noteKey + note.dayt_create"
                class="bottom-15 weight-600"
            >
                <a href="" @click.prevent="getFile({ id: note.id })">
                    {{ note.dayt_create }} Progress Note. {{ client_data.last_name }}, {{ client_data.first_name }}.pdf
                </a>
            </div>
        </section>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal';
    import { calibrateMenu, generateId, saveFile } from '@/util/genericUtilityFunctions';
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import DiagnosisList from '@/components/general/Diagnosis/DiagnosisList';
    import { clients, companies, documents, file } from '@/util/apiRequests';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    import dayjs from 'dayjs';
    import { openPdf } from '@/util/pdf';
    import { openImage } from '@/util/image';

    export default {
        name: 'TreatmentPlan',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0,
            },
        },
        components: {
            FormClient,
            FormSignature,
            VIfWorkableModal,
            FormCompany,
            FormSignatureButton,
            DiagnosisList,
            SignConvertPdfModal,
        },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,

                client_data: { first_name: '', last_name: '' },
                dx_codes: [],
                justModified: null,
                originalOptions: [],
                calibrateMenu,
                counselorOptions: [],
                counselorOptionsOriginal: [],
                original_service_options: [],
                service_options: [],
                noteHistory: [],
                dateEditing: false,
                asam_options: [
                    { id: 0, text: 'N/A' },
                    { id: 1, text: 'Dimension 1' },
                    { id: 2, text: 'Dimension 2' },
                    { id: 3, text: 'Dimension 3' },
                    { id: 4, text: 'Dimension 4' },
                    { id: 5, text: 'Dimension 5' },
                    { id: 6, text: 'Dimension 6' },
                ],
            };
        },
        computed: {
            dob() {
                return this.client_data && this.client_data.dob
                    ? dayjs(this.client_data.dob.split('T')[0]).format('MM/DD/YYYY')
                    : '';
            },
            availableCounselors() {
                const selected = this.rec.counselor_team.map((c) => parseInt(c.id, 10));
                return this.counselorOptions.map((c) =>
                    selected.includes(c.value) ? { ...c, disabled: true } : { ...c, disabled: false }
                );
            },
            filteredServiceOptions() {
                const selectedIds = this.rec.services.map((s) => s.cpt_code);
                return this.service_options.map((s) => ({ ...s, disabled: selectedIds.includes(s.service_code_id) }));
            },

            dx_model: {
                get() {
                    // from rec.diagnosis_codes, the primary code

                    return this.rec.diagnosis_codes.find((d) => d.primary).data_id;
                },
                set(value) {
                    this.rec.diagnosis_codes = this.rec.diagnosis_codes.map((d) => {
                        if (d.data_id === value) {
                            return { ...d, primary: true };
                        } else {
                            return { ...d, primary: false };
                        }
                    });
                },
            },
            counselor_model: {
                get() {
                    // from rec.counselor_team, the primary code
                    return this.rec.counselor_team.find((c) => c.primary).data_id;
                },
                set(value) {
                    this.rec.counselor_team = this.rec.counselor_team.map((c) => {
                        if (c.data_id === value) {
                            return { ...c, primary: true };
                        } else {
                            return { ...c, primary: false };
                        }
                    });
                },
            },
            date: {
                get() {
                    return dayjs(this.rec.date).format('YYYY-MM-DD');
                },
                set(value) {
                    // change the date to the new value, but keep the time
                    const newDate = dayjs(value);
                    const oldDate = dayjs(this.rec.date);
                    this.rec.date = newDate.set('hour', oldDate.hour()).set('minute', oldDate.minute()).toDate();
                },
            },
            time: {
                get() {
                    return dayjs(this.rec.date).format('HH:mm');
                },
                set(value) {
                    // change the time to the new value, but keep the old date
                    const newDate = dayjs(this.rec.date);
                    this.rec.date = newDate
                        .set('hour', value.split(':')[0])
                        .set('minute', value.split(':')[1])
                        .toDate();
                },
            },
            dateFormatted() {
                return dayjs(this.rec.date).format('MM/DD/YYYY hh:mm A');
            },
        },
        methods: {
            asam_input(key, value) {
                this.rec.goals[key].asam_dimension = value;
                this.$forceUpdate();
            },
            asam_value(key) {
                const value = this.rec.goals[key].asam_dimension;
                return this.asam_options[value];
            },
            modifyRecord(property, value) {
                this.$set(this.rec, property, value);
            },
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async getFile(f) {
                if (f.id) {
                    let result = await this.$api.get(file.getFile(f.id));
                    if (result.data.file.file_type === 'pdf' || result.data.file.file_type === '.pdf') {
                        await openPdf(result.data?.file?.Body, result.data?.file?.file_name);
                    } else if (result.data.file.originalContentType.includes('image')) {
                        openImage(result.data?.file?.Body);
                    } else {
                        saveFile(
                            result.data?.file?.Body,
                            result.data?.file?.originalContentType,
                            result.data?.file?.file_name
                        );
                    }
                } else {
                    this.$toasted.error('No file found');
                }
            },
            async getCounselorOptions() {
                try {
                    let result = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                    this.counselorOptions = result.data.map((element) => {
                        return { text: `${element?.first_name} ${element?.last_name}`, value: element.id };
                    });
                    this.counselorOptionsOriginal = this.counselorOptions;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of counselors.');
                }
            },
            async get_dx_codes() {
                const res = await this.$api.get(`/services/dx-codes`);
                let options = res.data?.map((element) => {
                    return { service_code: element.service_code, abbrev: element.abbrev, id: element.id };
                });
                this.options = options;
                this.originalOptions = this.options;
                this.rows = res.data;
                this.dx_codes = res.data.map((element) => {
                    element.selected = {
                        service_code: element.service_code,
                        abbrev: element.abbrev,
                        id: element.id,
                    };
                    element.disabled = true;
                    return element;
                });
            },
            async get_client_info() {
                let result = await this.$api.get(clients.getClient(this.client_id));
                this.client_data = result.data[0];
            },
            async getNoteHistory() {
                try {
                    let criteria = {
                        filter: { 'files.client_id': [String(this.client_id)], file_kind: ['ProgressNote'] },
                        sort: { ['files.dayt_create']: 'Descending' },
                        page: {
                            num_per_page: 1000,
                            page_num: 1,
                        },
                    };

                    const res = await this.$api.post(documents.getList(), { criteria: criteria });

                    if (res.data?.rows?.length > 0) {
                        this.noteHistory = res.data.rows.map((note) => {
                            note.dayt_create = dayjs(note.date).format('MM/DD/YY');
                            return note;
                        });
                    }
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of shared files');
                }
            },
            async get_service_codes() {
                const res = await this.$api.get(`/service_fees/list/company/${this.$store.state.user.company_id}`);
                this.original_service_options = res.data;
                this.service_options = res.data.filter(
                    (fee) => fee.code_type === 'service' && (fee.source === 'CPT' || fee.source === 'HCPCS')
                );
            },
            serviceCodeDesc({ service_code, abbrev }) {
                if (service_code && abbrev) {
                    return `${service_code} ${abbrev}`;
                }
                return 'Select an option';
            },
            add_dx() {
                this.rec.diagnosis_codes.push({ data_id: generateId(), generated: true, selected: {} });
            },
            addCpt() {
                if (this.rec.services.length < 10) {
                    this.rec.services.push({
                        data_id: generateId(),
                        generated: true,
                        selected: {},
                        cpt_code: 0,
                        frequency: '',
                        duration: '',
                        completion_date: '',
                        disabled: false,
                    });
                } else {
                    this.$toasted.error('You can only add up to 10 services.');
                }
            },
            add_counselor() {
                if (this.rec.counselor_team.length > 0) {
                    this.rec.counselor_team.push({ data_id: generateId(), primary: false, id: '' });
                } else {
                    this.rec.counselor_team.push({ data_id: generateId(), primary: true, id: '' });
                }
            },
            add_medications() {
                if (this.rec.medications.length === 0) {
                    this.rec.medications.push({ text: '' });
                }
            },
            add_agency() {
                this.rec.agencies.push({ agency_name: '', agency_plan: '' });
            },
            add_goal() {
                this.rec.goals.push({
                    data_id: generateId(),
                    generated: true,
                    goal: '',
                    goal_date: '',
                    asam_dimension: 0,
                    objectives: [],
                    interventions: [],
                });
            },
            add_referral() {
                this.rec.referrals.push({
                    data_id: generateId(),
                    contact: '',
                    name: '',
                });
            },
            del_goal(ndx) {
                this.rec.goals.splice(ndx, 1);
            },
            add_objective(ndx) {
                this.rec.goals[ndx].objectives.push({
                    data_id: generateId(),
                    generated: true,
                    objective: '',
                    objective_date: '',
                });
            },
            del_objective(goalndx, ndx) {
                this.rec.goals[goalndx].objectives.splice(ndx, 1);
            },
            add_intervention(ndx) {
                this.rec.goals[ndx].interventions.push({
                    data_id: generateId(),
                    generated: true,
                    intervention: '',
                    parties: '',
                });
            },
            remove_medication(ndx) {
                this.rec.medications = this.rec.medications.filter((med, index) => index !== ndx);
            },
            remove_provider(ndx) {
                this.rec.providers = this.rec.providers.filter((prov, index) => index !== ndx);
            },
            remove_agency(ndx) {
                this.rec.agencies = this.rec.agencies.filter((prov, index) => index !== ndx);
            },
            remove_counsellor(ndx) {
                this.rec.counselor_team = this.rec.counselor_team.filter((prov, index) => index !== ndx);
                // if there is no primary, set the first one to primary
                const isAPrimary = this.rec.counselor_team.find((counsellor) => counsellor.primary);
                if (!isAPrimary && this.rec.counselor_team.length > 0) {
                    this.rec.counselor_team[0].primary = true;
                }
            },
            remove_referral(ndx) {
                this.rec.referrals = this.rec.referrals.filter((prov, index) => index !== ndx);
            },
            toggleDateEditing() {
                this.dateEditing = !this.dateEditing;
            },
            del_intervention(goalndx, ndx) {
                this.rec.goals[goalndx].interventions.splice(ndx, 1);
            },
            async remove_dx(index) {
                this.rec.diagnosis_codes.splice(index, 1);
            },
            async removeCpt(index) {
                this.rec.services.splice(index, 1);
            },
            async init() {
                await Promise.all([this.get_dx_codes(), this.getCounselorOptions(), this.get_service_codes()]);
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);

                const fieldsForDataId = [
                    'goals',
                    'services',
                    'agencies',
                    'problem_array',
                    'counselor_team',
                    'medications',
                    'diagnosis_codes',
                    'referrals',
                ];

                fieldsForDataId.forEach((field) => {
                    this.rec[field].forEach((item) => {
                        item.data_id = item.data_id || generateId();
                    });
                });

                for (let goal in this.rec.goals) {
                    if (!this.rec.goals[goal].asam_dimension) {
                        this.rec.goals[goal].asam_dimension = 0;
                    }

                    this.rec.goals[goal].objectives.forEach((item) => {
                        item.data_id = item.data_id || generateId();
                    });
                    this.rec.goals[goal].interventions.forEach((item) => {
                        item.data_id = item.data_id || generateId();
                    });
                }

                if (!this.rec.med_dir_signature){
                    this.rec.med_dir_signature = false
                }

                if (!this.rec.date) this.rec.date = new Date();

                await this.get_client_info();
                await this.getNoteHistory();

                this.loading = 0;
            },
            printView() {
                window.print();
            },
        },
        async created() {
            await this.init();
            // set timeout to run get_time every second
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal) this.openModalOverlay();
                if (!newVal) {
                    this.closeModalOverlay();
                }
            },
        },
    };
</script>
